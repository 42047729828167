import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from "./account";

export const getInstitutionLocations = createAsyncThunk(
  "location/getInstitutionLocations",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/locations/institution/${payload?.institutionId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const getInstitutionLocationsWithPagination = createAsyncThunk(
  "location/getInstitutionLocationsWithPagination",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/locations/institution/${payload?.institutionId}?offset=${payload?.offset || 0}&limit=${payload?.limit || 20}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body)
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const getLocationById = createAsyncThunk(
  "location/getLocationById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/locations/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getLocationSchemaByInstitutionId = createAsyncThunk(
  "location/getLocationSchemaByInstitutionId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/locations/schema/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delLocations = createAsyncThunk(
  "location/delLocations",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/locations/${payload?.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addLocations = createAsyncThunk(
  "location/addLocations",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/locations`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editLocations = createAsyncThunk(
  "location/editLocations",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/locations/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editBulkLocationsCSV = createAsyncThunk(
  "locations/editBulkLocationsCSV",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/locations/bulk-csv`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getRevertLocations = createAsyncThunk(
  "client/revertLocations",
  async (payload, thunkApi) => {
    try {
      const url =
        `${process.env.REACT_APP_SERVER}/api/locations/revert/institution/${payload?.institutionId}`;

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const revertLocationById = createAsyncThunk(
  "client/revertLocationById",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/location/revert/id/${payload?.id}`;
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);