import { createSlice } from "@reduxjs/toolkit";
import {
  getClientSchema,
  addClient,
  getAllClients,
  getClientById,
  editClientById,
  delClientById,
  getClientByIdLazy,
  editBulkClients,
  editBulkClientsCSV,
  revertClients,
  revertClientById,
  getAllClientsWithPagination
} from "../actions/clients";
import { addEmail } from "../actions/email";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingGetClientSchema: false,
  isLoadingAddClient: false,
  isLoadingGetAllclients: false,
  isLoadingGetClientById: false,
  isLoadingEditClientById: false,
  clientByInstitutionById: {},
  isLoadingDelClient: false,
  isLoadingEditBulkClients: false,
  isLoadingEditBulkClientsCSV: false,
  isLoadingRevertClients: false,
  isLoadingRevertClientById: false,
  revertClientsData: [],
  clientSchema: [],
  clientList: [],
  clientListWithPagination:[],
  clientDetails: [],
  clientGoals: [],
  clientGoalsSchema: [],
  clientProducts: [],
  clientProductsSchema: [],
  clientProductTag: {},
  clientTags: {},
  clientEmail: [],
  clientReports: [],
  clientContractTemplates: [],
  clientContracts: [],
  tabDetails:{},
  totalClientCount:null
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    clearClientSchema(state) {
      state.clientSchema = [];
    }
  },
  extraReducers: {
    [getClientSchema.pending]: (state, action) => {
      state.isLoadingGetClientSchema = true;
      state.error = false;
    },
    [getClientSchema.fulfilled]: (state, action) => {
      state.isLoadingGetClientSchema = false;
      state.clientSchema = action?.payload?.data;
      state.error = false;
    },
    [getClientSchema.rejected]: (state, action) => {
      state.isLoadingGetClientSchema = false;
      state.error = true;
    },
    [getClientById.pending]: (state, action) => {
      state.isLoadingGetClientById = true;
      state.error = false;
    },
    [getClientById.fulfilled]: (state, action) => {
      state.isLoadingGetClientById = false;
      state.clientDetails = action?.payload?.data?.client;
      state.clientSchema = action?.payload?.data?.schema;
      state.error = false;
      state.clientGoals = action?.payload?.data?.goals;
      state.clientGoalsSchema = action?.payload?.data?.goalsSchmea;
      state.clientProducts = action?.payload?.data?.product_data;
      state.clientProductsSchema = action?.payload?.data?.product_schemas;
      state.clientProductTag = action?.payload?.data?.product_tag?.[0];
      state.clientTags = action?.payload?.data?.client_tags;
      state.clientEmail = action?.payload?.data?.emails;
      state.clientReports = action?.payload?.data?.reports;
      state.clientContractTemplates = action?.payload?.data?.contract_templates;
      state.clientContracts = action?.payload?.data?.contracts;
    },
    [getClientById.rejected]: (state, action) => {
      state.isLoadingGetClientById = false;
      state.error = true;
    },
    [getClientByIdLazy.pending]: (state, action) => {
      state.isLoadingGetClientById = true;
      state.error = false;
    },
    [getClientByIdLazy.fulfilled]: (state, action) => {
      state.isLoadingGetClientById = false;
      state.clientDetails = action?.payload?.data?.client;
      state.clientSchema = action?.payload?.data?.schema;
      state.error = false;
      state.clientProducts = action?.payload?.data?.product_data;
      state.clientProductsSchema = action?.payload?.data?.product_schemas;
      state.clientProductTag = action?.payload?.data?.product_tag?.[0];
      state.clientTags = action?.payload?.data?.client_tags;
      state.clientEmail = action?.payload?.data?.emails;
      state.clientReports = action?.payload?.data?.reports;
      state.clientContractTemplates = action?.payload?.data?.contract_templates;
      state.clientContracts = action?.payload?.data?.contracts;
    },
    [getClientByIdLazy.rejected]: (state, action) => {
      state.isLoadingGetClientById = false;
      state.error = true;
    },
    [addEmail.fulfilled]: (state, action) => {
      if (action?.payload?.module === "client") {
        state.clientEmail = [...(state?.clientEmail?.length ? state?.clientEmail : []), ...(action?.payload?.returnedData?.data?.length ? action?.payload?.returnedData?.data : [])];
      }
      state.error = false;
    },
    [addClient.pending]: (state, action) => {
      state.isLoadingAddClient = true;
      state.error = false;
    },
    [addClient.fulfilled]: (state, action) => {
      state.isLoadingAddClient = false;
      state.clientDetails = action?.payload?.data;
      state.error = false;
    },
    [addClient.rejected]: (state, action) => {
      state.isLoadingAddClient = false;
      state.error = true;
    },
    [getAllClients.pending]: (state, action) => {
      state.isLoadingGetAllclients = true;
      state.error = false;
    },
    [getAllClients.fulfilled]: (state, action) => {
      state.isLoadingGetAllclients = false;
      state.clientList = action?.payload?.data?.clients;
      state.clientByInstitutionById = action?.payload?.data?.byId;
      state.clientSchema = action?.payload?.data?.schema;
      state.clientTags = action?.payload?.data?.client_tags;
      state.error = false;
    },
    [getAllClients.rejected]: (state, action) => {
      state.isLoadingGetAllclients = false;
      state.error = true;
    },
    [getAllClientsWithPagination.pending]: (state, action) => {
      state.isLoadingGetAllclients = true;
      state.error = false;
    },
    [getAllClientsWithPagination.fulfilled]: (state, action) => {
      state.isLoadingGetAllclients = false;
      state.clientList = action?.payload?.data?.clients;
      state.clientByInstitutionById = action?.payload?.data?.byId;
      state.clientSchema = action?.payload?.data?.schema;
      state.clientTags = action?.payload?.data?.client_tags;
      state.tabDetails = action?.payload?.data?.client_status_count;
      state.totalClientCount = action?.payload?.data?.len;
      state.error = false;
    },
    [getAllClientsWithPagination.rejected]: (state, action) => {
      state.isLoadingGetAllclients = false;
      state.error = true;
    },
    [editClientById.pending]: (state, action) => {
      state.isLoadingEditClientById = true;
      state.error = false;
    },
    [editClientById.fulfilled]: (state, action) => {
      state.isLoadingEditClientById = false;
      state.clientDetails = action?.payload?.data?.client;
      state.error = false;
    },
    [editClientById.rejected]: (state, action) => {
      state.isLoadingEditClientById = false;
      state.error = true;
    }, //delClientById isLoadingDelClient
    [delClientById.pending]: (state, action) => {
      state.isLoadingDelClient = true;
      state.error = false;
    },
    [delClientById.fulfilled]: (state, action) => {
      state.isLoadingDelClient = false;
      state.error = false;
    },
    [delClientById.rejected]: (state, action) => {
      state.isLoadingDelClient = false;
      state.error = true;
    },
    [editBulkClients.pending]: (state, action) => {
      state.isLoadingEditBulkClients = true;
      state.error = false;
    },
    [editBulkClients.fulfilled]: (state, action) => {
      state.isLoadingEditBulkClients = false;
      state.error = false;
    },
    [editBulkClients.rejected]: (state, action) => {
      state.isLoadingEditBulkClients = false;
      state.error = true
    },
    [editBulkClientsCSV.pending]: (state, action) => {
      state.isLoadingEditBulkClientsCSV = true;
      state.error = false;
    },
    [editBulkClientsCSV.fulfilled]: (state, action) => {
      state.isLoadingEditBulkClientsCSV = false;
      state.error = false;
    },
    [editBulkClientsCSV.rejected]: (state, action) => {
      state.isLoadingEditBulkClientsCSV = false;
      state.error = true
    },
    [revertClients.pending]: (state, action) => {
      state.isLoadingRevertClients = true;
      state.error = false
    },
    [revertClients.fulfilled]: (state, action) => {
      state.isLoadingRevertClients = false;
      state.error = false;
      state.revertClientsData = action?.payload?.data;
    },
    [revertClients.rejected]: (state, action) => {
      state.isLoadingRevertClients = false;
      state.error = true;
    },
    [revertClientById.pending]: (state, action) => {
      state.isLoadingRevertClientById = true;
      state.error = false
    },
    [revertClientById.fulfilled]: (state, action) => {
      state.isLoadingRevertClientById = false;
      state.error = false
    },
    [revertClientById.rejected]: (state, action) => {
      state.isLoadingRevertClientById = false;
      state.error = true
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.client.error;
export const getIsLoadingClientSchema = (state) =>
  state.client.isLoadingGetClientSchema;
export const getIsLoadingAddClient = (state) => state.client.isLoadingAddClient;
export const getIsLoadingGetAllClients = (state) =>
  state.client.isLoadingGetAllclients;
export const getIsLoadingGetClientById = (state) =>
  state.client.isLoadingGetClientById;
export const getIsLoadingEditClientById = (state) =>
  state.client.isLoadingEditClientById;
export const getIsLoadingEditBulkClients = (state) =>
  state.client.isLoadingEditBulkClients;
export const getIsLoadingEditBulkClientsCSV = (state) =>
  state.client.isLoadingEditBulkClientsCSV
export const getIsLoadingRevertClients = (state) =>
  state.client.isLoadingRevertClients;
export const getRevertClientsData = (state) =>
  state.client.revertClientsData;
export const getIsLoadingRevertClientById = (state) =>
  state.client.isLoadingRevertClientById
export const getIsLoadingDelClient = (state) => state.client.isLoadingDelClient;
export const getClientSchemaArray = (state) => state.client.clientSchema;
export const getClientList = (state) => state.client.clientList;
export const getClientDetails = (state) => state.client.clientDetails;
export const getClientsForInstitutionById = (state) => {
  return state.client.clientByInstitutionById;
};

export const getClientGoals = (state) => state.client.clientGoals;
export const getClientGoalsSchema = (state) => state.client.clientGoalsSchema;
export const getClientProducts = (state) => state.client.clientProducts;
export const getClientProductsSchema = (state) => state.client.clientProductsSchema;
export const getClientProductTag = (state) => state.client.clientProductTag;
export const getClientTags = (state) => state.client.clientTags;
export const getClientEmail = (state) => state.client.clientEmail;
export const getClientReports = (state) => state.client.clientReports;
export const getClientContractTemplates = (state) => state.client.clientContractTemplates;
export const getClientContracts = (state) => state.client.clientContracts;
export const getTabDetails = (state) => state.client.tabDetails;
export const getTotalClientCount = (state) => state.client.totalClientCount;
export const { clearClientSchema } =
  clientSlice.actions;

export default clientSlice;
