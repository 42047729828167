import { createSlice } from "@reduxjs/toolkit";
import {
  getInstitutionLocations,
  delLocations,
  getLocationSchemaByInstitutionId,
  addLocations,
  getLocationById,
  editLocations,
  editBulkLocationsCSV,
  revertLocationById,
  getRevertLocations,
  getInstitutionLocationsWithPagination
} from "../actions/location";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingInstitutionLocations: false,
  isLoadingDeleteLocation: false,
  isLoadingGetLocationSchema: false,
  isLoadingPostLocations: false,
  isLoadingGetLocationById: false,
  isLoadingRevertLocationById:false,
  isLoadingRevertLocations:false,
  revertLocationsData:[],
  editBulkLocationsCSV:false,
  locations: [],
  locationSchema: [],
  locationDetails: {},
  locationClients: [],
  locationVendors: [],
  locationClientsSchema: [],
  locationVendorsSchema: [],
  locationGoals: [],
  locationGoalsSchema: [],
  locationProducts: [],
  locationProductsSchema: [],
  locationProductTag: [],
  clientTag: {},
  vendorTag: {},
  tabDetails: [],
  totalTabCount:null
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: {
    [getInstitutionLocations.pending]: (state, action) => {
      state.isLoadingInstitutionLocations = true;
      state.error = false;
    },
    [getInstitutionLocations.fulfilled]: (state, action) => {
      state.isLoadingInstitutionLocations = false;
      state.locations = action?.payload?.locations;
      state.locationSchema = action?.payload?.location_schema;
      state.error = false;
    },
    [getInstitutionLocations.rejected]: (state, action) => {
      state.isLoadingInstitutionLocations = false;
      state.error = true;
    },
    
    [getInstitutionLocationsWithPagination.pending]: (state, action) => {
      state.isLoadingInstitutionLocations = true;
      state.error = false;
    },
    [getInstitutionLocationsWithPagination.fulfilled]: (state, action) => {
      state.isLoadingInstitutionLocations = false;
      state.locations = action?.payload?.locations;
      state.locationSchema = action?.payload?.location_schema;
      state.tabDetails = action?.payload?.tabDetails;
      state.currentTabCount = action?.payload?.current_tab_count;
      state.totalTabCount = action?.payload?.total_count;
      state.error = false;
    },
    [getInstitutionLocationsWithPagination.rejected]: (state, action) => {   
      state.isLoadingInstitutionLocations = false;
      state.error = true;
    },
    [getLocationById.pending]: (state, action) => {
      state.isLoadingGetLocationById = true;
      state.error = false;
    },
    [getLocationById.fulfilled]: (state, action) => {
      state.isLoadingGetLocationById = false;
      state.locationSchema = action?.payload?.location_schema;
      state.locationDetails = action?.payload?.location;
      state.locationClients = action?.payload?.clients;
      state.locationEvents = action?.payload?.events;
      state.locationClientsSchema = action?.payload?.clientSchema;
      state.locationGoals = action?.payload?.goals;
      state.locationGoalsSchema = action?.payload?.goalsSchmea;
      state.locationProducts = action?.payload?.product_data;
      state.locationProductsSchema = action?.payload?.product_schemas;
      state.locationProductTag = action?.payload?.product_tag?.[0];
      state.locationVendors = action?.payload?.vendors;
      state.locationVendorsSchema = action?.payload?.vendorSchema;
      state.clientTag = action?.payload?.clientTag;
      state.vendorTag = action?.payload?.vendorTag;
      state.error = false;
    },
    [getLocationById.rejected]: (state, action) => {
      state.isLoadingGetLocationById = false;
      state.error = true;
    },
    [getLocationSchemaByInstitutionId.pending]: (state, action) => {
      state.isLoadingGetLocationSchema = true;
      state.error = false;
    },
    [getLocationSchemaByInstitutionId.fulfilled]: (state, action) => {
      state.isLoadingGetLocationSchema = false;
      state.locationSchema = action?.payload;
      state.error = false;
    },
    [getLocationSchemaByInstitutionId.rejected]: (state, action) => {
      state.isLoadingGetLocationSchema = false;
      state.error = true;
    },
    [delLocations.pending]: (state, action) => {
      state.isLoadingDeleteLocation = true;
      state.error = false;
    },
    [delLocations.fulfilled]: (state, action) => {
      state.isLoadingDeleteLocation = false;
      state.error = false;
    },
    [delLocations.rejected]: (state, action) => {
      state.isLoadingDeleteLocation = false;
      state.error = true;
    },
    [addLocations.pending]: (state, action) => {
      state.isLoadingPostLocations = true;
      state.error = false;
    },
    [addLocations.fulfilled]: (state, action) => {
      state.isLoadingPostLocations = false;
      state.locationDetails = action?.payload;
      state.error = false;
    },
    [addLocations.rejected]: (state, action) => {
      state.isLoadingPostLocations = false;
      state.error = true;
    },
    [editLocations.pending]: (state, action) => {
      state.isLoadingEditLocations = true;
      state.error = false;
    },
    [editLocations.fulfilled]: (state, action) => {
      state.isLoadingEditLocations = false;
      state.locationDetails = action?.payload;
      state.error = false;
    },
    [editLocations.rejected]: (state, action) => {
      state.isLoadingEditLocations = false;
      state.error = true;
    },
    [editBulkLocationsCSV.pending]: (state,action) => {
      state.editBulkLocationsCSV=true;
      state.error = false;
    },
    [editBulkLocationsCSV.rejected]: (state,action) => {
      state.editBulkLocationsCSV=false;
      state.error = true;
    },
    [editBulkLocationsCSV.fulfilled]: (state,action) => {
      state.editBulkLocationsCSV=false;
      state.error = false;
    },
    [revertLocationById.pending]: (state,action) => {
      state.isLoadingRevertLocationById=true;
      state.error =false;
    },
    [revertLocationById.fulfilled]:(state,action) =>{
      state.isLoadingRevertLocationById=false;
      state.error=false;
    },
    [revertLocationById.rejected] :(state,action) =>{
      state.isLoadingRevertLocationById=false;
      state.error=true
    },
    [getRevertLocations.pending]: (state,action) => {
      state.isLoadingRevertLocations=true;
      state.error =false;
    },
    [getRevertLocations.fulfilled]:(state,action) =>{
      state.isLoadingRevertLocations=false;
      state.error=false;
      state.revertLocationsData=action?.payload?.data;
    },
    [getRevertLocations.rejected] :(state,action) =>{
      state.isLoadingRevertLocations=false;
      state.error=true
    },

    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.location.error;
export const getIsLoadingInstitutionLocations = (state) =>
  state.location.isLoadingInstitutionLocations;
export const getIsLoadingDeleteLocations = (state) =>
  state.location.isLoadingDeleteLocation;
export const getIsLoadingGetLocationSchema = (state) =>
  state.location.isLoadingGetLocationSchema;
export const getIsLoadingPostLocations = (state) =>
  state.location.isLoadingPostLocations;
export const getInstitutionLocationsArray = (state) => state.location.locations;
export const getLocationSchema = (state) => state.location.locationSchema;
export const getIsLoadingGetLocationById = (state) =>
  state.location.isLoadingGetLocationById;
export const getIsLoadingEditLocationsBulkCSV = (state) =>
  state.location.editBulkLocationsCSV
export const getLocationDetails = (state) => state.location.locationDetails;
export const getIsLoadingEditLocations = (state) =>
  state.location.isLoadingEditLocations;
export const getIsLoadingRevertLocation = (state)=>
  state.location.isLoadingRevertLocations
export const getIsLoadingRevertLocationById=(state)=>
  state.location.isLoadingRevertLocationById;
export const getRevertLocationsData=(state)=>
  state.location.revertLocationsData

export const getLocationClients = (state) => state.location.locationClients;
export const getLocationEvents = (state) => state.location.locationEvents;
export const getLocationClientsSchema = (state) =>
  state.location.locationClientsSchema;
export const getLocationGoals = (state) => state.location.locationGoals;
export const getLocationGoalsSchema = (state) =>
  state.location.locationGoalsSchema;
export const getLocationProducts = (state) => state.location.locationProducts;
export const getLocationProductsSchema = (state) =>
  state.location.locationProductsSchema;
export const getLocationProductTag = (state) =>
  state.location.locationProductTag;
export const getLocationVendors = (state) =>
  state.location.locationVendors;
export const getLocationVendorsSchema = (state) =>
  state.location.locationVendorsSchema;
export const getClientTag = (state) =>
  state.location.clientTag;
export const getVendorTag = (state) =>
  state.location.vendorTag;
export const getTabDetails = (state) => state.location.tabDetails;
export const getTotalTabCount = (state) => state.location.totalTabCount;
export default locationSlice;
